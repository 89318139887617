import React from "react";
import logo from "../assets/images/BOOK COLOR.png";
import "./Content.css";
import book2 from "../assets/images/BOOK COLOR.png";
import rock from "../assets/images/rock.jpg";
import math from "../assets/images/mat.jpg";
import science from "../assets/images/science.jpg";
import hindi from "../assets/images/abhigyan.jpg";

export const Content = () => {
  return (
    <div className=" bg-[#FFF1DB]">
      <div className="container mx-auto my-4">
        <h4 className="py-5">Latest From WOODAPPLE Publication Blog</h4>
        <div className="grid grid-cols-3 gap-4 mb-5" id="slide">
          <img className="" src={book2}></img>
          <img className="" src={book2}></img>
          <img className="" src={book2}></img>
        </div>
      </div>
      <hr className="py-9" />
      <h4 className="py-9">Our Flipbooks</h4>
      <div className="grid grid-cols-4 gap-4 ms-8" id="slide">
        <a
          href="https://woodapplepublications.com/econtent/rock/1/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={rock} alt="Book 1" />
        </a>
        <a
          href="https://woodapplepublications.com/econtent/maths/1/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={math} alt="Book 2" />
        </a>
        <a
          href="https://woodapplepublications.com/econtent/science/1/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={science} alt="Book 3" />
        </a>
        <a
          href="https://woodapplepublications.com/econtent/abhigyan/4/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={hindi} alt="Book 4" />
        </a>
      </div>
    </div>
  );
};
